@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header_container{
    text-align: center;
    height: 100%;
    position: relative;
}

/*=====================CTA=============*/

.cta{
    margin-top: 2.5rem;
    display: flex;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    justify-content: center;
}

/*=====================SOCIALS=============*/

.header_socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    position: absolute;
    left:0;
    bottom: 3rem;
}

.header_socials::after{
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/*===============ME=============*/

.me{
    /*background: linear-gradient(var(--color-primary), transparent);*/
    width: 22rem;
    height:15rem;
    position: absolute;
    left:calc(50% - 11rem);
    margin-top: 2rem;
    /*overflow: hidden;*/
    border-radius: 12rem 12rem 0 0;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}


/*============SCROLL DOWN===============*/

.scroll_down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

@media screen and (max-width: 1024px) {
    header{
        height: 100vh;
    }
}

@media screen and (max-width: 600px) {
    header{
        height: 100vh;
    }
    .header_socials, .scroll_down{
        display: none;
    }
}
nav {
    background: rgba(0, 0, 0, 0.3);
    width: -webkit-max-content;
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 2rem;
    display: flex;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    border-radius: 3rem;
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-white);
    font-size: 1.1rem;
}

nav a:hover {
    background: rgba(0, 0, 0, 0.3);
}

nav a.active {
    background: var(--color-bg);
    color: var(--color-white);
}
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background: var(--color-primary-variant);
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    color: var(--color-white);
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;

    bottom: 100%;
    left: 50%;
    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}
.about_container {
    display: grid;
    grid-template-columns: 35% 50%;
    grid-gap: 15%;
    gap: 15%;
}

.about_me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    place-items: center;

}

.about_me-image{
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about_me-image:hover{
    transform: rotate(0deg);
}

.about_cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    gap: 1.5rem
}

.about_card{
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
}

.about_card:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about_icon{
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about_card h5{
    font-size: 0.95rem;
}

.about_card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about_content p{
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}


@media screen and (max-width: 1024px) {
    .about_container{
        grid-template-columns: 1fr;
        grid-gap: 0;
        gap: 0;
    }
    .about_me{
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about_content p{
        margin: 1rem 0 1.5rem;
    }
    .about_me-image{
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .about_me{
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about_cards{
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        gap: 1rem;
    }
    .about_content{
        text-align: center;
    }

    .about_content p{
        margin: 1.5rem 0;
    }
    .about_me-image{
        display: none;
    }
}
.container.contact_container{
    width: 40%;
    /*display: grid;*/
    /*grid-template-columns: 50%;*/
    grid-gap: 12% ;
    gap: 12% ;
    padding-top: 2.5rem;
}

.contact_options{
    display: flex;
    flex-direction: column;
    grid-gap: 1.2rem;
    gap: 1.2rem;
}

.contact_option{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact_option:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.contact_option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact_option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/*==============form==================*/

form{
    display: flex;
    flex-direction: column;
    grid-gap: 1.2rem;
    gap: 1.2rem;
}

input, textarea{
    width :100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

@media screen and (max-width: 1024px) {
    .container.contact_container{
        width: var(--container-width-md);
    }
}

@media screen and (max-width: 600px) {
    .container.contact_container{
        width: var(--container-width-sm);
    }
}
.experience_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    gap: 2rem;
    padding-top: 2rem;
}


.experience_container>div{
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience_container>div:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}
.experience_container>div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience_content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2rem;
    row-gap: 2rem;
}

.experience_details{
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.experience_detail-icon{
    margin-top: 6px;
    color: var(--color-primary);
}

@media screen and (max-width: 1024px) {
    .experience_container{
        grid-template-columns: 1fr;
    }

    .experience_container>div{
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience_content{
        padding: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .experience_container{
        grid-gap: 1rem;
        gap: 1rem;
    }

    .experience_container>div{
        width: 100%;
        padding: 2rem 1rem;
    }

}
.qualification__tabs {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    margin: 5rem auto;

}

.qualification__button {
    font-size: 1.125rem;
    font-weight:  500;
    cursor: pointer
}

.qualification__sections{
    width: 50%;
    margin: 5rem auto;
    /*transition: var(--transition);*/
}

.qualification__button:hover {
    color: var(--color-primary)
}

.qualification__icon {
    font-size: 1.8rem;
    margin-right: 0.25rem;
}

.qualification__data {
    display: grid;
    grid-template-columns: 1fr -webkit-max-content 1fr;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
}

.qualification__title {
    font-size: .938rem;
    font-weight: 500;
}

.qualification__subtitle {
    display: inline-block;
    font-size: .813rem;
    margin-bottom: 1rem;
}

.qualification__calendar {
    font-size: .75rem;
    color: var(--color-light);
    /* margin-bottom: var(--mb-1) */

}

.qualification__rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--color-primary);
    border-radius: 50%;
}

.qualification__line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--color-primary);
    transform: translate(6px, -7px)
}

.qualification__content[data-content] {
    display: none;

}

.qualification__active[data-content] {
    display: block;
}

.qualification__button.qualification__active {
    color: var(--color-primary)
}

@media screen and (max-width: 1024px) {
    .qualification__sections, .qualification__tabs{
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .qualification__sections, .qualification__tabs{
        width: 100%;
    }

}
.portfolio_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5rem;
    gap: 2.5rem;
    padding-top: 2.5rem;
}

.portfolio_item{
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio_item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio_item-image{
    /*border-radius: 1.5rem;*/
    overflow: hidden;
    margin-bottom: 1rem;
}

.portfolio_item h3{
    margin: 1.2rem 0 2rem;
}

.portfolio_item-cta{
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
}

@media screen and (max-width: 1024px) {
    .portfolio_container{
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.2rem;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .portfolio_container{
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        gap: 1rem;
    }
}
.container.testimonials_container{
    width:40%;
    padding-top: 2.5rem;
}
.testimonial{
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    -webkit-user-select: none;
            user-select: none;
}

.designation{
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 60%;
    margin: 0 auto;
}

.review{
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 1rem auto;
}

@media screen and (max-width: 1024px) {
    .container.testimonials_container{
        width: 60%;
    }
}

@media screen and (max-width: 600px) {
    .container.testimonials_container{
        width: var(--container-width-sm);
    }

    .review{
        width: var(--container-width-sm);
    }

}
footer{
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a{
    color: var(--color-bg);
}

.footer_logo{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer_socials{
 display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer_socials a{
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer_socials a:hover{
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer_copyright{
    margin-bottom: 4rem;
    color: var(--color-bg)
}



@media screen and (max-width: 600px) {
    .permalinks{
        flex-direction: column;
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }
    .footer_socials{
        margin-bottom: 2.6rem;
    }
}
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    /*background image*/
}


.container{
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5{
    font-weight: 500;
}

h1{
    font-size: 2.5rem;
}

section{
    margin-top: 8rem;
    /*height: 100vh;*/
}

section>h2, section>h5{
    text-align: center;
    color: var(--color-light);
}

section>h2{
    color: var(--color-primary);
    margin-bottom: 0;
}

.text-light{
    color: var(--color-light);
}

a{
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover{
    color: var(--color-white)
}

.btn{
    width: -webkit-max-content;
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg)
}

img{
    display: block;
    width: 100%;
    object-fit: contain;
}

@media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
}
@media screen and (max-width: 600px) {
    .container{
        width: var(--container-width-sm);
    }

    section>h2{
        margin-top: 2rem;
    }
}


