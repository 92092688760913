.qualification__tabs {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    margin: 5rem auto;

}

.qualification__button {
    font-size: 1.125rem;
    font-weight:  500;
    cursor: pointer
}

.qualification__sections{
    width: 50%;
    margin: 5rem auto;
    /*transition: var(--transition);*/
}

.qualification__button:hover {
    color: var(--color-primary)
}

.qualification__icon {
    font-size: 1.8rem;
    margin-right: 0.25rem;
}

.qualification__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.qualification__title {
    font-size: .938rem;
    font-weight: 500;
}

.qualification__subtitle {
    display: inline-block;
    font-size: .813rem;
    margin-bottom: 1rem;
}

.qualification__calendar {
    font-size: .75rem;
    color: var(--color-light);
    /* margin-bottom: var(--mb-1) */

}

.qualification__rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--color-primary);
    border-radius: 50%;
}

.qualification__line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--color-primary);
    transform: translate(6px, -7px)
}

.qualification__content[data-content] {
    display: none;

}

.qualification__active[data-content] {
    display: block;
}

.qualification__button.qualification__active {
    color: var(--color-primary)
}

@media screen and (max-width: 1024px) {
    .qualification__sections, .qualification__tabs{
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .qualification__sections, .qualification__tabs{
        width: 100%;
    }

}