header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header_container{
    text-align: center;
    height: 100%;
    position: relative;
}

/*=====================CTA=============*/

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/*=====================SOCIALS=============*/

.header_socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left:0;
    bottom: 3rem;
}

.header_socials::after{
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/*===============ME=============*/

.me{
    /*background: linear-gradient(var(--color-primary), transparent);*/
    width: 22rem;
    height:15rem;
    position: absolute;
    left:calc(50% - 11rem);
    margin-top: 2rem;
    /*overflow: hidden;*/
    border-radius: 12rem 12rem 0 0;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}


/*============SCROLL DOWN===============*/

.scroll_down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

@media screen and (max-width: 1024px) {
    header{
        height: 100vh;
    }
}

@media screen and (max-width: 600px) {
    header{
        height: 100vh;
    }
    .header_socials, .scroll_down{
        display: none;
    }
}